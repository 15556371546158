<template>
  <div>
    <FloatWpp/>
    <SubMenu />
    <Menu />
    <AbaGenerica
      title="Quiropraxia"
      text="A quiropraxia é uma especialização da área de Fisioterapia, responsável pelo diagnóstico, tratamento e prevenção de problemas nas articulações, músculos e nos ossos através de um conjunto de técnicas fisioterapêuticas que são capazes de movimentar colocando-os na posição correta. As técnicas da quiropraxia devem ser aplicadas por um profissional habilitado e podem ser indicadas como tratamento complementar e alternativo para luxações e para aliviar dores nos quadril, costas, pescoço e ombros."
      imageUrl="blank.png"
      videoUrl="quiropraxia.mp4"
    />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import AbaGenerica from "@/components/AbaGenerica";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from '../components/FloatWpp.vue';

export default {
  name: "Quiropraxia",
  components: {
    SubMenu,
    Menu,
    AbaGenerica,
    Mapa,
    Footer,
    FloatWpp,
  },
};
</script>
