<template>
  <div>
    <div class="containerAbaGenerica">
      <div class="leftAbaGenerica">
        <h3 style="color: #00a89b">{{ title }}</h3>
        <p>{{ text }}</p>
        <slot></slot>
      </div>
      <div
        class="rightAbaGenerica"
        v-bind:style="{ 
          'background-image': 'url(' + this.bg(imageUrl) + ')',
          'background-position': posImage ? posImage : 'center',
        }"
      >
        <div
          v-if="subimageUrl"
          class="subFoto"
          v-bind:style="{
            'background-image': 'url(' + this.bg(subimageUrl) + ')',
          }"
        ></div>
        <video
          v-if="videoUrl"
          :src="this.vd(videoUrl)"
          autoplay
          loop
          muted
        ></video>
      </div>
    </div>
    <opcoes-generica :tecnicas="servico" :name-skill="nameSkill" />
  </div>
</template>

<script>
import OpcoesGenerica from "./OpcoesGenerica.vue";

export default {
  components: { OpcoesGenerica },
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    imageUrl: {
      type: String,
    },
    subimageUrl: {
      type: String,
    },
    videoUrl: {
      type: String,
    },
    servico: {
      type: Object,
    },
    nameSkill: {
      type: String,
    },
    posImage: {
      type: String,
    }
  },
  methods: {
    bg(imageS) {
      return require("../assets/" + imageS);
    },
    vd(videoB) {
      return require("../assets/video/" + videoB);
    },
  },
};
</script>

<style lang="scss" scoped>
.containerAbaGenerica {
  padding: 80px;
  justify-content: center;
  display: flex;
}
.leftAbaGenerica {
  width: 30%;
  p {
    color: #2c3e50;
  }
}
.rightAbaGenerica {
  overflow: hidden;
  background-size: cover;
  margin-left: 5%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 30%;
  height: 400px;
  padding: 18px;
  position: relative;
  flex-direction: column-reverse;
  align-items: flex-end;
  display: flex;
}
.rightAbaGenerica video {
  top: -340px;
  left: 0px;
  width: 100%;
  position: absolute;
}
.subFoto {
  width: 180px;
  height: 180px;
  background-size: cover;
  background-position: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  align-items: flex-end;
  display: flex;
}
.subFoto h5 {
  width: 100%;
  background-color: rgb(0, 168, 155);
  text-align: center;
  color: white;
  margin: 0px;
  padding: 5px;
  bottom: 0px;
}
@media screen and (max-width: 1160px) {
  .containerAbaGenerica {
    flex-direction: column;
  }
  .leftAbaGenerica {
    width: 100%;
  }
  .rightAbaGenerica {
    width: 100%;
    height: 650px;
    margin: unset;
  }
}
@media screen and (max-width: 830px) {
  .rightAbaGenerica {
    height: unset;
    height: 400px;
  }
  .rightAbaGenerica video {
    margin-top: unset;
    top: 0px;
  }
}
</style>
