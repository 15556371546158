<template>
  <div>
    <!-- Default Menu -->
    <div id="defaultMenu" class="containerMenu">
      <router-link to="/">Home</router-link>
      <router-link to="/Clinica">
        <span>Clínica</span>
      </router-link>
      <div class="list-container">
        <span class="list-menu">Serviços</span>
        <div class="list-dropdown">
          <div class="list-dropdown-options">
            <router-link
              v-for="(service, j) in services"
              v-bind:key="j"
              :to="service.path"
            >
              <span>{{ service.text }}</span>
              <img src="./../assets/arrowCyan.png" />
            </router-link>
          </div>
        </div>
      </div>
      <router-link to="/contato">Contato</router-link>
    </div>

    <!-- Mobile Menu -->
    <div id="menuMobile" class="containerMobile">
      <div class="hamburgerMobile">
        <button @click="openMenu()">
          <i class="fa fa-navicon"></i>
        </button>
      </div>
      <div id="optionsMobileMenu" class="containerMobileOptions">
        <router-link
          v-for="(optionM, i) in optionsMobile"
          v-bind:key="i"
          :to="optionM.path"
          >{{ optionM.text }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: true,
      services: [
        {
          path: "/fisioterapia",
          text: "Fisioterapia",
        },
        {
          path: "/quiropraxia",
          text: "Quiropraxia",
        },
        {
          path: "/massoterapia",
          text: "Massoterapia",
        },
        {
          path: "/kinesiotape",
          text: "Kinesiotape",
        },
        {
          path: "/alinhamento-postural",
          text: "Alinhamento Postural",
        },
        {
          path: "/atendimento-domiciliar",
          text: "Atendimento Domiciliar",
        },
        {
          path: "/liberacao-miofascial",
          text: "Liberação Miofascial"
        }
      ],
      optionsMobile: [
        {
          path: "/",
          text: "Home",
        },
        {
          path: "/clinica",
          text: "Clínica",
        },
        {
          path: "/contato",
          text: "Contato",
        },
      ],
    };
  },
  methods: {
    openMenu() {
      if (this.isOpen) {
        document.getElementById("optionsMobileMenu").style.display = "flex";
      } else {
        document.getElementById("optionsMobileMenu").style.display = "none";
      }
      this.isOpen = !this.isOpen;
    },
  },
  beforeMount() {
    this.optionsMobile.push(...this.services);
  },
};
</script>

<style lang="scss">
.containerMenu {
  width: 100%;
  border-bottom: 3px solid #00a89b;
  border-top: 1px solid rgb(230, 230, 230);
  justify-content: center;
  align-items: center;
  display: flex;
  // flex-direction: column;
  a {
    text-align: center;
    min-width: 60px;
    padding: 20px;
    text-decoration: none;
    font-weight: 600;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #00a89b;
    }
  }
}

.containerMobile {
  width: 100%;
  border-bottom: 3px solid #00a89b;
  flex-direction: column;
  display: none;
  padding-bottom: 10px;
  button {
    font-size: 22px;
    padding: 12px 20px;
    color: #2c3e50;
    background-color: unset;
    border: unset;
    cursor: pointer;
    outline: none;
    &:active {
      color: #00a89b;
    }
  }
}

// hide menu
.containerMobileOptions {
  flex-direction: column;
  display: none;
  overflow: hidden;
  a {
    padding: 20px;
    text-decoration: none;
    font-weight: 600;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #00a89b;
    }
  }
}

@media screen and (max-width: 1160px) {
  #defaultMenu {
    display: none;
  }
  #menuMobile {
    display: flex;
  }
}

// Dropdownlist
.list-container {
  position: relative;
  flex-direction: column;
  display: flex;
}
.list-menu {
  z-index: 99999;
  text-align: center;
  min-width: 60px;
  padding: 20px;
  text-decoration: none;
  font-weight: 600;
  color: #2c3e50;
  cursor: pointer;
  &:hover ~ .list-dropdown {
    display: flex;
  }
}
.list-dropdown {
  z-index: 99999;
  width: 100px;
  border-radius: 8px;
  position: absolute;
  display: none;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    display: flex;
  }
  a {
    justify-content: space-between;
    display: flex;
    text-align: left !important;
    &:hover {
      color: #00a89b;
    }
    img {
      height: 15px;
    }
  }
}
.list-dropdown-options {
  width: 300px;
  border-radius: 8px;
  margin-top: 70px;
  display: flex;
  background-color: white;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border: 1px solid #00a89b;
  &:hover {
    display: flex;
  }
  a {
    text-align: left !important;
    &:hover {
      color: #00a89b;
    }
  }
}
</style>
