var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"galeriaTitle"},[_vm._v("Galeria")]),_c('div',{staticClass:"containerGale"},[_c('div',{staticClass:"left",on:{"click":function($event){return _vm.openModalGalery(
          'https://firebasestorage.googleapis.com/v0/b/fisioadapt-8c87d.appspot.com/o/diplomas.jpg?alt=media&token=e60d10cb-f2fe-41d5-ba4b-245befc84572'
        )}}}),_c('div',{staticClass:"right"},[_c('div',{staticClass:"boxItem",on:{"click":function($event){return _vm.openModalGalery(
            'https://firebasestorage.googleapis.com/v0/b/fisioadapt-8c87d.appspot.com/o/maca.jpg?alt=media&token=375175a5-1af5-471f-8a5a-8bc893c0fd4f'
          )}}}),_c('div',{staticClass:"boxItem",on:{"click":function($event){return _vm.openModalGalery(
            'https://firebasestorage.googleapis.com/v0/b/fisioadapt-8c87d.appspot.com/o/diplomasdois.jpg?alt=media&token=0c225e13-6084-42b5-9a38-fbecdda3c56e'
          )}}}),_c('div',{staticClass:"boxItem",on:{"click":function($event){return _vm.openModalGalery(
            'https://firebasestorage.googleapis.com/v0/b/fisioadapt-8c87d.appspot.com/o/fachada_equipamentos.jpg?alt=media&token=644a2021-8a28-4a97-ab46-d8f632c5955a'
          )}}})])]),_c('div',{staticClass:"modal",attrs:{"id":"modal"}},[_c('div',{staticClass:"containerModal",attrs:{"id":"containerModal"}},[_c('button',{on:{"click":function($event){return _vm.closeModal()}}},[_vm._v("X")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }