<template>
  <div>
    <FloatWpp />
    <SubMenu />
    <Menu />
    <AbaGenerica
      title="Fisioterapia"
      text="Fisioterapia é a ciência que estuda, diagnostica, previne e trata os
          distúrbios cinético funcionais, ou seja, trata a desordem da
          funcionalidade humana baseando-se na compreensão das ciências
          biológicas e da saúde. O objetivo da Fisioterapia é preservar, manter,
          desenvolver ou restaurar a integridade dos órgãos, sistema e funções
          do organismo promovendo assim, maior qualidade de vida aos indivíduos,
          onde o movimento e as funções estejam ameaçadas pelo envelhecimento,
          lesão, dor, doenças, distúrbios ou outros fatores prejudicando o
          movimento que é fundamental para a vida."
      imageUrl="fiseoterapia.jpg"
    />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import AbaGenerica from "@/components/AbaGenerica";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from "../components/FloatWpp.vue";

export default {
  name: "Fisioterapia",
  components: {
    SubMenu,
    Menu,
    AbaGenerica,
    Mapa,
    Footer,
    FloatWpp,
  },
};
</script>
