<template>
  <div>
    <FloatWpp/>
    <SubMenu />
    <Menu />
    <AbaGenerica
      title="Alinhamento Postural"
      text="O alinhamento postural é baseado em vários estudos científicos e em experiência profissional em fisioterapia e quiropraxia. Alinhando a coluna com mobilização e com técnicas quiropráticas podemos obter melhora da capacidade de estabilidade e maior qualidade de movimento, evitando vícios posturais e aderências miotendínias. Vida é movimento e por isso não podemos esquecer de alongar e fortalecer os músculos. Com esse fortalecimento fazemos com que a coluna vertebral sofra menor impacto e stress, causado pela rotina diária e sedentarismo. Na vida moderna que hoje é o nosso cotidiano o alinhamento postural é o tratamento mais indicado e o mais produtivo para o alívio de dores e diminuindo o uso de medicamentos prevenindo futuramente tratamentos mais invasivos."
      imageUrl="alinhamento.jpeg"
    />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import AbaGenerica from "@/components/AbaGenerica";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from '../components/FloatWpp.vue';

export default {
  name: "AlinhamentoPostural",
  components: {
    SubMenu,
    Menu,
    AbaGenerica,
    Mapa,
    Footer,
    FloatWpp
  },
};
</script>
