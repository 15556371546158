<template>
  <div>
    <div class="containerTecnicas">
      <div class="leftTecnicas">
        <h3>Técnicas</h3>
        <div class="tecnicaCont">
          <router-link to="/Fisioterapia">
            <span>Fisioterapia</span>
            <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>
        <div class="tecnicaCont">
          <router-link to="/Quiropraxia">
            <span>Quiropraxia</span>
            <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>
        <div class="tecnicaCont">
          <router-link to="/Kinesiotape">
            <span>Kinesiotape</span>
            <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>
        <div class="tecnicaCont">
          <router-link to="/alinhamento-postural">
            <span>Alinhamento Postural</span>
            <i class="fa fa-arrow-right"></i>
          </router-link>
        </div>
      </div>
      <div class="rightTecnicas">
        <div class="itemImage"></div>
        <div class="itemImage"></div>
        <div class="itemImage"></div>
        <div class="itemImage"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.containerTecnicas {
  color: #2c3e50;
  background-color: #f4f4f4;
  padding: 50px 0px;
  justify-content: center;
  display: flex;
}
.leftTecnicas {
  width: 35%;
  margin-right: 5%;
}
.tecnicaCont {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  a {
    color: #2c3e50;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: left;
    padding: 20px;
    border: 0px;
    background-color: transparent;
    justify-content: space-between;
    cursor: pointer;
    display: flex;
  }
}
.rightTecnicas {
  width: 22%;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  display: flex;
}
.itemImage {
  min-width: 200px;
  min-height: 200px;
  margin: 1%;
  background-size: cover;
  background-position: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.itemImage:nth-child(1) {
  background-image: url("../assets/galeria/galeria_1.jpg");
}
.itemImage:nth-child(2) {
  background-image: url("../assets/galeria/galeria_2.jpg");
}
.itemImage:nth-child(3) {
  background-image: url("../assets/galeria/galeria_3.jpg");
}
.itemImage:nth-child(4) {
  background-image: url("../assets/galeria/galeria_4.jpg");
}
@media screen and (max-width: 998px) {
  .containerTecnicas {
    flex-direction: column;
    align-items: center;
  }
  .leftTecnicas {
    width: 45%;
    min-width: 200px;
    margin: 0px;
    margin-left: 0%;
    margin-bottom: 5%;
    h3 {
      text-align: center;
    }
  }
  .rightTecnicas {
    width: 45%;
  }
}
</style>
