<template>
  <div class="home">
    <FloatWpp />
    <SubMenu />
    <Menu />
    <Banner />
    <Clinica />
    <Galeria />
    <Depoimento />
    <Tecnicas />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import Banner from "@/components/Banner.vue";
import Clinica from "@/components/Clinica.vue";
import Galeria from "@/components/Galeria.vue";
import Depoimento from "@/components/Depoimento.vue";
import Tecnicas from "@/components/Tecnicas.vue";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from "../components/FloatWpp.vue";

export default {
  name: "Home",
  components: {
    SubMenu,
    Menu,
    Banner,
    Clinica,
    Galeria,
    Tecnicas,
    Depoimento,
    FloatWpp,
    Mapa,
    Footer,
  },
};
</script>
