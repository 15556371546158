<template>
  <div>
    <footer>
      <div>
        <b class="headerMenuFooter">Menu do Rodapé</b>
        <span class="menuF">
          <router-link
            to="/"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Home</router-link
          ></span
        >
        <span class="menuF">
          <router-link
            to="/Clinica"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Clínica</router-link
          ></span
        >
        <span class="menuF">
          <router-link
            to="/Fisioterapia"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Fisioterapia</router-link
          ></span
        >
        <span class="menuF">
          <router-link
            to="/Quiropraxia"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Quiropraxia</router-link
          ></span
        >
        <span class="menuF">
          <router-link
            to="/Kinesiotape"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Kinesiotape</router-link
          ></span
        >
        <span class="menuF">
          <router-link
            to="/alinhamento-postural"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Alinhamento Postural</router-link
          ></span
        >
        <span class="menuF">
          <router-link
            to="/atendimento-domiciliar"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Atendimento Domiciliar</router-link
          ></span
        >
        <span class="menuF">
          <router-link
            to="/liberacao-miofascial"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Liberação Miofascial</router-link
          ></span
        >
        <!-- <span class="menuF">
          <router-link
            to="/Equipe"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Equipe</router-link
          ></span
        > -->
        <span class="menuF">
          <router-link
            to="/Contato"
            style="color: white; text-decoration: none"
            onMouseOver="this.style.color='#2c3e50'"
            onMouseOut="this.style.color='white'"
            >Contato</router-link
          ></span
        >
        <br /><br /><br />
        <p>© Fisioadapt 2020</p>
      </div>

      <div>
        <b class="headerMenuFooter">Redes Sociais</b>
        <!--<span>
          <a href="" target="_blank">
            <i class="fa fa-phone"></i>
            Telefone
            <br />
            +55 (47) 3237-8674
          </a>
        </span>!-->
        <span>
          <a
            href="https://wa.me/5504196940392?text=Ol%C3%A1%20vim%20pelo%20seu%20site,%20gostaria%20de%20agendar%20uma%20consulta."
            target="_blank"
          >
            <i class="fa fa-whatsapp"></i>
            Whatsapp
            <br />
            ‎+55 (41) 9694-0392
          </a>
        </span>
        <span>
          <a href="https://www.instagram.com/fisioadapt/" target="_blank">
            <i class="fa fa-instagram"></i>
            Instagram
          </a>
        </span>
        <span>
          <a
            href="https://www.facebook.com/Fisioadapt-295019674323829/"
            target="_blank"
          >
            <i class="fa fa-facebook"></i>
            &nbsp; Facebook
          </a>
        </span>
      </div>

      <div>
        <b class="headerMenuFooter">Contato</b>
        <a
          href="mailto:jefisio30@gmail.com?subject=Contato%20site%20Fisioadapt"
          style="color: white; text-decoration: none"
          onMouseOver="this.style.color='#2c3e50'"
          onMouseOut="this.style.color='white'"
        >
          <i class="fa fa-envelope-o"></i>
          jefisio83@gmail.com
        </a>
      </div>

      <div>
        <b class="headerMenuFooter">Sobre nós</b>
        <p class="Mission">
          A Fisioadapt atende com um método diferenciado onde cada sessão de
          fisioterapia é individualizada e adaptada aos seus pacientes.
          Oferecemos um Tratamento Fisioterapêutico personalizado e Quiropraxia
          com o intuito de melhorar sua qualidade de vida, humanizando e
          adaptando as suas necessidades diárias prevenindo doenças futuras.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  height: auto;
  background-color: #00a89b;
  padding: 50px 20px;
  color: white;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
  display: flex;
}
footer div {
  width: 300px;
  line-height: 15px;
  margin-bottom: 50px;
}
footer div span {
  display: block;
  color: white;
  padding: 8px 0px;
  cursor: pointer;
}
.headerMenuFooter {
  font-size: 20px;
  padding: 20px 0px;
  display: block;
}
span a {
  color: white;
  text-decoration: none;
  i {
    padding: 8px 2px;
  }
  &:hover {
    color: #2c3e50;
  }
}
.menuF {
  color: #2c3e50;
}
.Mission {
  line-height: 25px;
}
.marca {
  margin-left: 0px;
  padding: 15px 0px;
  font-size: 34px;
  color: white;
  font-weight: bold;
  text-shadow: -1px -1px 0px #000, 0px -1px 0px #000, 1px -1px 0px #000,
    -1px 0px 0px #000, 1px 0px 0px #000, -1px 1px 0px #000, 0px 1px 0px #000,
    1px 1px 0px #000;
}
.socialMedia {
  width: 50px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 8px;
}
.socialMedia a {
  color: white !important;
}

@media screen and (max-width: 1160px) {
  footer {
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
}
</style>
