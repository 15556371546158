<template>
  <div>
    <FloatWpp/>
    <SubMenu />
    <Menu />
    <ContatoComp />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import ContatoComp from "@/components/ContatoComp.vue";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from '../components/FloatWpp.vue';

export default {
  name: "Contato",
  components: {
    SubMenu,
    Menu,
    ContatoComp,
    Mapa,
    Footer,
    FloatWpp,
  },
};
</script>
