<template>
  <div>
    <FloatWpp />
    <SubMenu />
    <Menu />
    <AbaGenerica
      title="Liberação Miofascial"
      text="A Fisioadapt oferece um ambiente próprio para atendimento de liberação miofascial, garantindo alta qualidade e conforto para os nossos pacientes. A liberação miofascial é uma técnica terapêutica que vem sendo muito utilizada em atletas, e praticantes de atividades físicas que visa aliviar tensões e restrições nos tecidos musculares e fasciais, promovendo a melhoria da mobilidade dos tecidos, recuperação muscular e a redução da dor. Nosso ambiente de atendimento é cuidadosamente projetado para proporcionar um espaço acolhedor, onde os pacientes possam desfrutar dos benefícios dessa técnica."
      imageUrl="blank.png"
      videoUrl="liberacaomiofascial.mp4"
    />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import AbaGenerica from "../components/AbaGenerica.vue";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from "../components/FloatWpp.vue";

export default {
  name: "Equipe",
  components: {
    SubMenu,
    Menu,
    AbaGenerica,
    Mapa,
    Footer,
    FloatWpp,
  },
};
</script>

<style>
</style>