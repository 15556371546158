<template>
  <div class="containerSubMenu">
    <div class="left">
      <router-link to="/">
        <img src="../assets/fisioadapt.png" alt="" />
        <h1><font style="color: rgb(44, 62, 80)">Fisio</font>adapt</h1>
      </router-link>
    </div>
    <div class="right">
      <!-- <div class="boxItem">
                <img src="../assets/whatsapp-logo-.png" alt="">
                <span>
                    Fale pelo Whatsapp
                    <br>
                    <b>(00) 98765-4321</b>
                </span>
            </div> -->
      <div class="boxItem">
        <img src="../assets/whatsapp-logo-.png" alt="" />
        <a
          href="https://wa.me/5504196940392?text=Ol%C3%A1%20vim%20pelo%20seu%20site,%20gostaria%20de%20agendar%20uma%20consulta."
          target=" 
               _blank"
          style="color: white; text-decoration: none"
        >
          <span>
            Fale pelo Whatsapp
            <br />
            <b>(41) 9694-0392</b>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.containerSubMenu {
  padding: 0px 20%;
  height: 120px;
  background-color: #00a89b;
  display: flex;
}
.left {
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  a {
    justify-content: center;
    align-items: center;
    display: flex;
    text-decoration: none;
  }
  img {
    margin: 11px;
    width: 80px;
  }
  h1 {
    color: white;
    padding-bottom: -1px;
  }
}
.right {
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .boxItem {
    margin: 0% 1%;
  }
}
.boxItem {
  padding: 20px;
  width: 200px;
  align-items: center;
  display: flex;
  img {
    width: 35px;
    height: 35px;
    margin-right: 5%;
  }
}
@media screen and (max-width: 1160px) {
  .containerSubMenu {
    justify-content: center;
    padding: 0%;
    height: unset;
  }
  .left {
    img {
      width: 35px;
    }
  }
  .right {
    display: none;
  }
}
</style>
