<template>
  <div>
    <div class="banner">
      <div>
        <img src="../assets/fisioadapt.png" alt="" width="60px" height="60px" />
        <h1><font style="color: rgb(44, 62, 80)">Fisio</font>adapt</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.banner {
  height: 400px;
  background-image: url("../assets/banner.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 12px;
  flex-direction: column-reverse;
  display: flex;
  div {
    align-items: center;
    display: flex;
  }
  h1 {
    font-size: 4.1em;
    color: #00a89b;
    margin: 1%;
    padding: 0px;
  }
}
@media screen and (max-width: 1160px) {
  .banner {
    flex-direction: column;
    h1 {
      font-size: 35px;
    }
  }
}
</style>
