<template>
  <div>
    <div class="containerClinica">
      <div class="clinicaLeft">
        <h3 style="color: #00a89b">Sobre a Fisioadapt</h3>
        <p>
          A Fisioadapt atende com um método diferenciado onde cada sessão de
          fisioterapia é individualizada e adaptada aos seus pacientes.
          Oferecemos um Tratamento Fisioterapêutico personalizado
          e Quiropraxia com o intuito de melhorar sua qualidade de vida,
          humanizando e adaptando as suas necessidades diárias prevenindo
          doenças futuras.
        </p>
      </div>
      <div class="clinicaRight"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.containerClinica {
  padding: 70px 15%;
  justify-content: center;
  display: flex;
}
.clinicaLeft {
  padding: 12px;
  margin-right: 5%;
  width: 40%;
}
.clinicaRight {
  width: 45%;
  height: 400px;
  background-image: url("../assets/home.png");
  background-position: 0px -70px;
  background-size: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  img {
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}
@media screen and (max-width: 1160px) {
  .containerClinica {
    flex-direction: column-reverse;
  }
  .clinicaLeft {
    padding: unset;
    margin-right: unset;
    text-align: center;
    width: 100%;
  }
  .clinicaRight {
    width: 100%;
  }
}
</style>
