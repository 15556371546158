<template>
  <div class="container-wpp-button">
    <a
      href="https://wa.me/5504196940392?text=Ol%C3%A1%20vim%20pelo%20seu%20site,%20gostaria%20de%20agendar%20uma%20consulta."
      target="_blank"
      style="color: white; text-decoration: none"
    >
      <div class="float-wpp">
        <img src="./../assets/whatsapp-logo-.png" />
      </div>
    </a>
    <div class="container-text-button">
      <div class="text-button">
        <div class="triangle" />
        <span>Agendar agora!</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.float-wpp {
  justify-content: center;
  align-items: center;
  display: flex;
}
.container-wpp-button {
  background-color: #25d366;
  // background-color: red;
  position: fixed;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  right: 26px;
  bottom: 40px;
  padding: 12px;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: 0.2s;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover {
    margin-bottom: 8px;
    .container-text-button {
      opacity: 1;
      display: flex;
    }
  }
  img {
    width: 90%;
  }
}
.container-text-button {
  position: absolute;
  background-color: #efeae2;
  filter: drop-shadow( 0 6px 6px rgba(0, 0, 0, 0.23));
  padding: 8px 16px;
  border-radius: 6px;
  height: 30px;
  bottom: 10px;
  left: -166px;
  transition: 0.2s;
  justify-content: center;
  align-items: center;
  opacity: 0;
  display: none;
}

.triangle {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background-color: #efeae2;
  transform: rotate(90deg);
  position: absolute;
  height: 12px;
  width: 12px;
  right: -12px;
  top: 16px;
}
</style>
