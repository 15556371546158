<template>
  <div>
    <FloatWpp/>
    <SubMenu />
    <Menu />
    <AbaGenerica
      title="Kinesiotape"
      text="O kinesio tape é uma bandagem utilizada para acelerar a recuperação de uma lesão, aliviar dores musculares ou para estabilizar as articulações, permite o fluxo sanguíneo e não limita o movimento, podendo ser aplicada em qualquer lugar do corpo. Favorece a drenagem de líquidos que podem estar acumulados no local que causam dor na lesão muscular, além de aumentar a circulação sanguínea local e promover melhor desempenho muscular e diminuição da fadiga."
      imageUrl="kinesiotape.jpg"
    />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import AbaGenerica from "../components/AbaGenerica.vue";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from '../components/FloatWpp.vue';

export default {
  name: "Equipe",
  components: {
    SubMenu,
    Menu,
    AbaGenerica,
    Mapa,
    Footer,
    FloatWpp,
  },
};
</script>
