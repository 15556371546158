import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Clinica from "../views/Clinica.vue";
import Fisioterapia from "../views/Fisioterapia.vue";
import Quiropraxia from "../views/Quiropraxia.vue";
import Pilates from "../views/Pilates.vue";
import Kinesiotape from "../views/Kinesiotape.vue";
import AlinhamentoPostural from "../views/AlinhamentoPostural.vue";
import LiberacaoMiofascial from '../views/LiberacaoMiofascial.vue'
import Equipe from "../views/Equipe.vue";
import Contato from "../views/Contato.vue";
import Massoterapia from "../views/Massoterapia.vue";
import AtendimentoDomiciliar from "../views/AtendimentoDomiciliar.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Fisioadapt",
    },
  },
  {
    path: "/clinica",
    name: "Clinica",
    component: Clinica,
    meta: {
      title: "Clínica",
    },
  },
  {
    path: "/fisioterapia",
    name: "Fisioterapia",
    component: Fisioterapia,
    meta: {
      title: "Fisioadapt - Fisioterapia",
    },
  },
  {
    path: "/quiropraxia",
    name: "Quiropraxia",
    component: Quiropraxia,
    meta: {
      title: "Fisioadapt - Quiropraxia",
    },
  },
  // {
  //   path: "/pilates",
  //   name: "Pilates",
  //   component: Pilates,
  //   meta: {
  //     title: "Fisioadapt - Pilates",
  //   },
  // },
  {
    path: "/massoterapia",
    name: "Massoterapia",
    component: Massoterapia,
    meta: {
      title: "Fisioadapt - Massoterapia",
    },
  },
  {
    path: "/atendimento-domiciliar",
    name: "AtendimentoDomiciliar",
    component: AtendimentoDomiciliar,
    meta: {
      title: "Fisioadapt - Atendimento Domiciliar",
    },
  },
  {
    path: "/alinhamento-postural",
    name: "AlinhamentoPostural",
    component: AlinhamentoPostural,
    meta: {
      title: "Fisioadapt - Alinhamento Postural",
    },
  },
  {
    path: "/kinesiotape",
    name: "Kinesiotape",
    component: Kinesiotape,
    meta: {
      title: "Fisioadapt - Kinesiotape",
    },
  },
  {
    path: "/liberacao-miofascial",
    name: "Liberação Miofascial",
    component: LiberacaoMiofascial,
    meta: {
      title: "Fisioadapt - Liberação Miofascial",
    },
  },
  {
    path: "/equipe",
    name: "Equipe",
    component: Equipe,
    meta: {
      title: "Equipe",
    },
  },
  {
    path: "/contato",
    name: "Contato",
    component: Contato,
    meta: {
      title: "Contato",
    },
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "Sobre",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
