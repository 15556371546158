<template>
  <div v-if="tecnicas" class="opcoesContainer">
    <h1>Técnicas {{ nameSkill }}</h1>
    <div class="listContainer scroll">
      <div class="boxOption" v-for="(tecnica, i) in tecnicas" v-bind:key="i">
        <span>
          <b>{{ tecnica.title }}</b>
        </span>
        <p>{{ tecnica.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "massoterapia",
    };
  },
  props: {
    nameSkill: {
      type: String,
    },
    tecnicas: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.opcoesContainer {
  padding: 0px 10vw;
  padding-bottom: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e6e6e6;
  background-color: #f4f4f4;
  display: flex;
  h1 {
    padding: 15px;
    color: #00a89b;
  }
}
.listContainer {
  width: 90vw;
  padding-bottom: 20px;
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
}
.boxOption {
  min-width: 300px;
  height: 370px;
  overflow: hidden;
  margin: 8px;
  color: #121212;
  background-color: rgba(0, 0, 0, 0.03);
  flex-direction: column;
  display: flex;
  span {
    text-align: center;
    padding: 12px;
    background-color: #00a89b;
    letter-spacing: 0.5px;
    color: white;
  }
  p {
    padding: 8px 10px;
    margin: 0px;
  }
}
.scroll {
  overflow-x: scroll;
}
.scroll::-webkit-scrollbar {
  height: 12px;
}
.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
</style>
