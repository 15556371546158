<template>
  <div>
    <div class="mapa">
      <h1>Venha nos conhecer</h1>
      <h5>Estamos aqui para você</h5>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.3293368658633!2d-49.27434110000001!3d-25.460676199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce50cadd1c4ab%3A0xb09e8f1c7efd2149!2sFisioadapt%20-%20Fisioterapia%20Domiciliar%20e%20Quiropraxia%20em%20Curitiba!5e0!3m2!1spt-BR!2sbr!4v1706540628231!5m2!1spt-BR!2sbr"
        width="100%"
        height="400px"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
      <br />
      <a
        href="https://wa.me/5504196940392?text=Ol%C3%A1%20vim%20pelo%20seu%20site,%20gostaria%20de%20agendar%20uma%20consulta."
        target="_blank"
        class="whatsappMeet"
      >
        Fala agora pelo Whatsapp<img
          src="../assets/whatsapp-logo-.png"
          alt=""
          width="20px
            "
      /></a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.mapa {
  border-top: 1px solid #e6e6e6;
  padding-bottom: 28px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  h1 {
    font-size: 24px;
    color: #00a89b;
    text-align: center;
    margin: 0px;
    padding: 35px;
    padding-bottom: 0px;
  }
  h5 {
    font-size: 15px;
    text-align: center;
    padding-top: 0px;
    margin-top: 0px;
  }
}
.whatsappMeet {
  text-decoration: none;
  background-color: #00a89b;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border: 0px;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  height: 50px;
  width: 300px;
  margin: 1%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  img {
    margin-left: 5%;
  }
}
</style>
