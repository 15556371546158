<template>
  <div>
    <FloatWpp/>
    <SubMenu />
    <Menu />
    <AbaGenerica
      title="Nossa clínica"
      text="A Clínica Fisioadapt atende com um conceito diferenciado onde cada sessão de fisioterapia é individualizada e adaptada às necessidades dos pacientes. Possui um ambiente confortável e humanizado para que nosso trabalho tenha um desempenho mais eficaz, alcançando sempre os melhores resultados. A Clínica Fisioadapt possui um atendimento especializado em fisioterapia e Quiropraxia, proporcionando melhora da qualidade de vida de seus pacientes, diminuindo lesões patológicas existentes ou prevenindo doenças futuras."
      imageUrl="home2.png"
    />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import AbaGenerica from "@/components/AbaGenerica";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from '../components/FloatWpp.vue';

export default {
  name: "Clinica",
  components: {
    SubMenu,
    Menu,
    Mapa,
    AbaGenerica,
    Footer,
    FloatWpp
  },
};
</script>
