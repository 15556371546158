<template>
  <div>
    <FloatWpp/>
    <SubMenu />
    <Menu />
    <AbaGenerica
      title="Pilates"
      text="Pilates é um método de exercícios desenvolvido por Joseph Pilates na década de 1920 que visa trabalhar a conexão entre mente e corpo, como uma unidade, de modo a melhorar a consciência corporal e dessa forma promover outros benefícios.
      É atualmente uma técnica reconhecida para tratamento e prevenção de problemas na coluna vertebral. A modalidade também é eficaz para aprimorar outras habilidades, como flexibilidade, consciência corporal, equilíbrio, resistência muscular e para reabilitação de lesões e reeducar movimentos"
      imageUrl="pilates2.png"
    />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import AbaGenerica from "@/components/AbaGenerica";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from '../components/FloatWpp.vue';

export default {
  name: "Pilates",
  components: {
    SubMenu,
    Menu,
    AbaGenerica,
    Mapa,
    Footer,
    FloatWpp
  },
};
</script>
