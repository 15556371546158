<template>
  <div>
    <div class="containerContato">
      <div class="leftContato">
        <h3 style="color: #00a89b">Contato</h3>
        <div class="contacts">
          <span>
            <a
              href="mailto:jefisio30@gmail.com?subject=Contato%20site%20Fisioadapt"
              style="color: ; text-decoration: none"
            >
              <i class="fa fa-envelope-o"></i>
              Email
              <br />
              jefisio83@gmail.com
            </a>
          </span>

          <br />

          <span>
            <a href="https://www.instagram.com/fisioadapt/" target="_blank">
              <i class="fa fa-instagram"></i>
              Instagram
            </a>
          </span>

          <br />

          <span>
            <a
              href="https://www.facebook.com/Fisioadapt-295019674323829/"
              target="_blank"
            >
              <i class="fa fa-facebook"></i>
              &nbsp; Facebook
            </a>
          </span>

          <br />

          <!--<span>
            <a href="" target="_blank">
              <i class="fa fa-phone"></i>
              Telefone
              <br />
              +55 (47) 3237-8674
            </a>
          </span>
          
          <br />
          !-->

          <span>
            <a
              href="https://wa.me/55047988803880?text=Ol%C3%A1%20vim%20pelo%20seu%20site,%20gostaria%20de%20agendar%20uma%20consulta."
              target="_blank"
            >
              <i class="fa fa-whatsapp"></i>
              Whatsapp
              <br />
              ‎+55 (41) 9694-0392
            </a>
          </span>

          <br />

          <span>
            <a href="">
              <i class="fa fa-map-marker"></i>
              Local
              <br />
              José cadilhe - 1500 <br />Água verde, Curitiba
            </a>
          </span>
        </div>
      </div>
      <div class="rightContato"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.containerContato {
  padding: 80px;
  justify-content: center;
  display: flex;
}
.leftContato {
  width: 30%;
}
.rightContato {
  background-image: url("../assets/contato.png");
  background-size: cover;
  background-position: center;
  margin-left: 5%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 30%;
  height: 550px;
}
.contacts {
  color: black !important;
  flex-direction: column;
  display: flex;
  span {
    a {
      font-size: 18px;
      text-decoration: none;
      font-weight: 600;
      color: #2c3e50;
      &:hover {
        color: rgb(0, 168, 155);
      }
      i {
        font-size: 20px;
        padding: 5px;
        padding-left: 0%;
        color: rgb(0, 168, 155);
      }
    }
  }
}
@media screen and (max-width: 1160px) {
  .containerContato {
    flex-direction: column;
  }
  .leftContato {
    width: 100%;
  }
  .rightContato {
    width: 100%;
    margin: unset;
  }
}
</style>
