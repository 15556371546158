<template>
  <div>
    <h3 class="galeriaTitle">Galeria</h3>
    <div class="containerGale">
      <div
        @click="
          openModalGalery(
            'https://firebasestorage.googleapis.com/v0/b/fisioadapt-8c87d.appspot.com/o/diplomas.jpg?alt=media&token=e60d10cb-f2fe-41d5-ba4b-245befc84572'
          )
        "
        class="left"
      ></div>

      <div class="right">
        <div
          @click="
            openModalGalery(
              'https://firebasestorage.googleapis.com/v0/b/fisioadapt-8c87d.appspot.com/o/maca.jpg?alt=media&token=375175a5-1af5-471f-8a5a-8bc893c0fd4f'
            )
          "
          class="boxItem"
        ></div>
        <div
          @click="
            openModalGalery(
              'https://firebasestorage.googleapis.com/v0/b/fisioadapt-8c87d.appspot.com/o/diplomasdois.jpg?alt=media&token=0c225e13-6084-42b5-9a38-fbecdda3c56e'
            )
          "
          class="boxItem"
        ></div>
        <div
          @click="
            openModalGalery(
              'https://firebasestorage.googleapis.com/v0/b/fisioadapt-8c87d.appspot.com/o/fachada_equipamentos.jpg?alt=media&token=644a2021-8a28-4a97-ab46-d8f632c5955a'
            )
          "
          class="boxItem"
        ></div>
      </div>
    </div>
    <div id="modal" class="modal">
      <div id="containerModal" class="containerModal">
        <button @click="closeModal()">X</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: "",
      modalCont: "",
    };
  },
  methods: {
    openModalGalery: function (images) {
      this.modal = document.getElementById("modal");

      this.modalCont = document.getElementById("containerModal");
      this.modalCont.style.backgroundImage = "url('" + images + "')";
      this.modal.scrollIntoView();

      this.modal.style.display = "flex";
    },
    closeModal() {
      this.modal.style.display = "none";
    },
  },
};
</script>

<style scoped>
body {
  margin: 0%;
  padding: 0%;
}
.galeriaTitle {
  text-align: center;
  margin-bottom: -50px;
  color: rgb(0, 168, 155);
}
.containerGale {
  border-top: 1px solid #e6e6e6;
  padding: 50px 0px;
  padding-top: 75px;
  background-color: #f4f4f4;
  justify-content: center;
  display: flex;
}

.right {
  width: 200px;
  height: 550px;
  margin-left: 1%;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
}

.left {
  height: 450px;
  margin-right: 1%;
  width: 42%;
  padding: 50px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  background-image: url("../assets/galeria/galeria_1.jpg");
  background-position: 0px -120px;
  background-size: cover;
  cursor: pointer;
}

.boxItem {
  height: 25%;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  /* background-image: url("https://i.imgur.com/pj1FKJu.png"); */
  background-position: center;
  background-size: cover;
  cursor: pointer;
}
.boxItem:nth-child(1) {
  background-image: url("../assets/galeria/galeria_3.jpg");
}
.boxItem:nth-child(2) {
  background-image: url("../assets/galeria/galeria_2.jpg");
}
.boxItem:nth-child(3) {
  background-image: url("../assets/galeria/galeria_4.jpg");
}

#modal {
  width: 100%;
  height: 800px;
  background-color: rgba(0, 0, 0, 0.397);
  justify-content: center;
  align-items: center;
  display: none;
}
.containerModal {
  max-width: 1000px;
  width: 70%;
  height: 80%;
  background-color: white;
  background-position: center;
  background-size: cover;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  position: relative;
}

.containerModal button {
  font-size: 26px;
  font-weight: bold;
  width: 60px;
  height: 60px;
  right: 0px;
  margin-top: -60px;
  margin-right: -60px;
  background-color: transparent;
  border: 0px;
  color: #f4f4f4;
  cursor: pointer;
  position: absolute;
}

@media screen and (max-width: 1160px) {
  .containerGale {
    flex-direction: column;
    align-items: center;
  }
  .left {
    width: 100%;
    margin-bottom: 2.5%;
    padding: unset;
    height: 150px;
  }
  .right {
    width: 100%;
    margin-left: 0%;
    background-position: center;
    justify-content: unset;
    overflow: hidden;
  }
  .boxItem {
    width: 100%;
    height: 150px;
    margin: unset;
    margin-bottom: 2.5%;
    padding: 0px;
  }
  .boxItem:nth-child(3n) {
    margin-bottom: unset;
  }
}
</style>
