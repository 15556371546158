<template>
  <div>
    <FloatWpp/>
    <SubMenu />
    <Menu />
    <AbaGenerica
      title="Massoterapia"
      text="A massoterapia consiste em técnicas de manipulação corporal, feitas manualmente por um profissional, que:

melhoram a saúde;
promovem o equilíbrio corporal e energético;
oferecem uma sensação geral de bem-estar.
Através do toque em regiões estratégicas do corpo, o massoterapeuta consegue trabalhar pontos de dor física e mental de cada pessoa.  "
      imageUrl="massoterapia.jpg"
      :servico="massoterapia"
      :name-skill="name"
    />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import AbaGenerica from "@/components/AbaGenerica";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from '../components/FloatWpp.vue';

export default {
  name: "Quiropraxia",
  components: {
    SubMenu,
    Menu,
    AbaGenerica,
    Mapa,
    Footer,
    FloatWpp,
  },
  data() {
    return {
      name: "Massoterapia",
      massoterapia: [
        {
          title: "Massagem sueca",
          text:
            "É uma massagem que trata dores musculares, alonga a musculatura encurtada e aumenta a circulação sanguínea. É de longe a massagem ocidental mais conhecida no mundo e é muito usada para atletas, como relaxamento e em tratamentos de dores na coluna. Em geral o massagista sueco estuda a anatomia e a fisiologia tradicionais.",
        },
        {
          title: "Trigger Points",
          text:
            "É a mesma coisa que pontos de gatilho. Desativa pontos sensíveis na musculatura que podem emitir dores que radiam longe do ponto de origem.",
        },
        {
          title: "Massagem desportiva",
          text:
            "Ajuda a retirar resíduos metabólicos do organismo após uma atividade física ou a estimular a musculatura antes de uma atividade física. A massagem desportiva aumenta a elasticidade dos músculos e estimula o fluxo de sangue para aumentar a limpeza de músculos cansados. Muito indicada para pessoas amadoras, profissionais e ou praticantes de qualquer atividade física.",
        },
        {
          title: "Liberação miofascial",
          text:
            "Técnica que é muito utilizada como auxílio da massagem desportiva. O método consiste em liberar as fáscias entre feixes musculares para permitir mais flexibilidade, aliviar dores e definir a musculatura.",
        },
        {
          title: "Massagem relaxante",
          text:
            "Reduz o estresse causado pelo dia-a-dia. Proporciona relaxamento total do corpo, dando uma sensação de leveza e bem-estar. A massagem relaxante é muito usada em spas, pousadas e hotéis onde a pessoa busca relaxar. Há várias técnicas de massagem relaxante, embora a maioria seja proveniente da massagem sueca.",
        },
        {
          title: "Drenagem Linfática",
          text:
            "Esta massagem ajuda na eliminação de líquido que por algum motivo está retido no organismos mais especificamente aplicada na retenção de líquidos,pode ser utilizada juntamente com a modeladora para redução de medidas.",
        },
        {
          title: "Ventosa",
          text:
            "Usando copos, criando um vácuo com sugador ou fogo,aumentando o espaço entre pele e os músculos,trabalhando assim também liberação miofascial e retirando a toxidade  tecido a ser tratado.",
        },
      ],
    };
  },
};
</script>
