<template>
  <div>
    <FloatWpp/>
    <SubMenu />
    <Menu />
    <AbaGenerica
      title="Atendimento Domiciliar"
      text="Fisioterapia Home Care ou fisioterapia domiciliar é um conjunto de ações fisioterapêuticas que permitem a realização dessas práticas em ambiente domiciliar mediante atuação do fisioterapeuta em conjunto com  a equipe multidisciplinar.
Essa modalidade de atendimento contribui para um estado de promoção, manutenção e reabilitação da saúde, tendo como principal benefício a comodidade já que não exige a locomoção do paciente até o centro de saúde.
O serviço de home care teve origem nos Estados Unidos em Nova York no Memorial Hospital, com o professor Bluestone. A modalidade foi desenvolvida como alternativa aos custos elevados das internações hospitalares por iniciativa dos próprios hospitais, por causa das pressões do governo e dos seguros de saúde.
A fisioadapt atende no conforto de seu lar com todo o cuidado e atenção que teria em ambiente clínico, com a vantagem do paciente não precisar se deslocar da sua moradia."
      imageUrl="domiciliardois.jpg"
    />
    <Mapa />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import SubMenu from "@/components/SubMenu.vue";
import Menu from "@/components/Menu.vue";
import AbaGenerica from "@/components/AbaGenerica.vue";
import Mapa from "@/components/Mapa.vue";
import Footer from "@/components/Footer.vue";
import FloatWpp from '../components/FloatWpp.vue';

export default {
  name: "Quiropraxia",
  components: {
    SubMenu,
    Menu,
    AbaGenerica,
    Mapa,
    Footer,
    FloatWpp,
  },
};
</script>

<style scoped>
.rightAbaGenerica video {
  top: -550px !;
  left: 0px;
  width: 100%;
  position: absolute;
}
</style>
