<template>
  <div class="containerDPALL">
    <div class="containerDepoimento">
      <div class="iconeDepoimento"></div>
      <div class="depoimento">
        <h1>DR. JEFFERSON CAMARGO</h1>
        <span><b>CREFITO-248293-F</b></span>
        <p>
          <b>Trajetória profissional</b>
          <br />
          <br />
          Em 2018 residiu em Brusque e trabalhou com Fisioterapia na Clínica
          Santa Rita onde aprimorou mais o método Fisioadapt durante três anos.
          Em 2021 sendo grato pela cidade de Brusque que o acolheu, abriu seu
          novo espaço com atendimento especializado em Fisioterapia, Quiropraxia, 
          com objetivo de adaptar seu tratamento humanizado a
          realidade de cada paciente obtendo resultados melhores e assim a
          Fisioadapt deixou de ser apenas um método, passando ser a marca da
          Empresa.
          <br />
          Em 2022 resolveu retornar a Curitiba sua cidade natal, e trazer o
          método fisioadapt,com eficácia comprovada em seus pacientes, iniciando
          uma nova trajetória,com atendimento ao público em geral, e também
          tendo o diferencial na fisioterapia domiciliar. Com boa aceitação na
          região aonde atende esperamos assim solidificar o trabalho que já
          estava sendo feito em Santa Catarina.
          <br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.containerDPALL {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  justify-content: center;
  display: flex;
}
.containerDepoimento {
  width: 1000px;
  color: #2c3e50;
  padding: 100px 0px;
  justify-content: space-between;
  display: flex;
}
.iconeDepoimento {
  width: 300px;
  height: 300px;
  background-image: url("../assets/doctor.jpg");
  background-size: cover;
  background-position: top;
  border: 8px solid #00a89b;
  border-radius: 50%;
}
.depoimento {
  width: 60%;
  p {
    text-align: justify;
    font-size: 15px;
  }
}
@media screen and (max-width: 1160px) {
  .containerDepoimento {
    width: unset;
    min-height: 750px;
    padding: 30px;
    justify-content: unset;
    align-content: center;
    flex-direction: column;
    display: flex;
  }
  .iconeDepoimento {
    margin: 0 auto;
  }
  .depoimento {
    width: 100%;
    h1 {
      font-size: 24px;
      text-align: center;
      margin: 40px auto;
    }
  }
}
</style>
