<template>
  <div>
    <div class="containerEquipe">
      <div class="leftEquipe">
        <h3>Dr. Jefferson Camargo</h3>
        <p>
          <b>Trajetória profissional</b>
          <br />
          <br />
          Atuando desde 2012 com massoterapeuta e querendo inovar e aperfeiçoar
          seus atendimentos ingressou na Faculdade de Fisioterapia da Uniandrade
          – Curitiba – PR no qual idealizou e coordenou o projeto Despertando a
          Formação que tinha como objetivo atividades de vivência em
          Fisioterapia na Instituição FEPE, que atende crianças com doenças de
          alta complexidade.
          <br />
          <br />
          Em 2017 após a conclusão da graduação ministrou aula de anatomia ao
          Curso Técnico de Enfermagem no Instituto Kern de Ciências Biomédicas e
          começou sua atuação como fisioterapeuta pela Central da Fisioterapia
          Home Care com atendimento domiciliar, criando com base acadêmico
          científica o método Fisioadapt, adaptando protocolos fisioterapêuticos
          conforme a necessidade de cada paciente e com isso obtendo melhoras
          significativas em diversas patologias.
          <br />
          <br />
          Em 2018 veio residir em Brusque e trabalhar com Fisioterapia na
          Clínica Santa Rita onde aprimorou mais o método Fisioadapt durante
          três anos.
          <br />
          Em 2021 sendo grato pela cidade de Brusque que o acolheu, abriu seu
          novo espaço com atendimento especializado em Fisioterapia e
          Quiropraxia com objetivo de adaptar seu tratamento humanizado a
          realidade de cada paciente obtendo resultados melhores e assim a
          Fisioadapt deixou de ser apenas um método, passando ser a marca da
          Empresa.
        </p>
      </div>
      <div class="rightEquipe">
        <!-- <div class="subFotoEquipe"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.containerEquipe {
  padding: 80px;
  justify-content: center;
  display: flex;
}
.leftEquipe {
  width: 30%;
  h3 {
    color: #00a89b;
  }
  b {
    color: #00a89b;
  }
}
.rightEquipe {
  background-image: url("../assets/doctor.jpg");
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-position-y: -30px;
  margin-left: 5%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 400px;
  height: 400px;
  flex-direction: column-reverse;
  align-items: flex-end;
  display: flex;
  .subFotoEquipe {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border: 4px solid #00a89b;
    background-image: url("../assets/massagemPescoco.png");
    background-position: -60px -15px;
    background-size: 165% 140%;
    background-color: #00a89b;
    margin-bottom: -20px;
    margin-right: -20px;
  }
}
@media screen and (max-width: 1160px) {
  .containerEquipe {
    flex-direction: column-reverse;
    align-items: center;
  }
  .leftEquipe {
    width: 100%;
  }
  .rightEquipe {
    margin: unset;
  }
}
</style>
